import React, { useState } from 'react';
import { ReactComponent as Reports } from '../../../lib/assets/icons/reports.svg';
import { ReactComponent as CompanySelect } from '../../../lib/assets/icons/company_select.svg';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { Link, useLocation, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { ReactComponent as Accounting } from '../../../lib/assets/icons/accounting.svg';
import PremiumCrown from '../../../lib/assets/images/premium-crown.png';
import StandardCrown from '../../../lib/assets/images/standard_crown.png';
import Invoice from '../../components/Icons/invoice';
import { ReactComponent as Humanresource } from '../../../lib/assets/icons/hr.svg';
import { ReactComponent as Bank } from '../../../lib/assets/icons/banking.svg';
import { ReactComponent as Tax } from '../../../lib/assets/icons/tax.svg';
import { getSidebarContent, getDrawerSidebar } from '@mui-treasury/layout';
import {
  Paper,
  makeStyles,
  Typography,
  SvgIcon,
  IconButton,
  Avatar,
  Divider,
  Box,
} from '@material-ui/core';
import { DashboardOutlined } from '@material-ui/icons';
import BrandLogo from 'lib/assets/icons/brand_logo.png';
import getAlphabethColor from 'lib/helpers/colorSelect';
import './sidebar.css';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { User } from 'service/userService';
import { MessageFeedback } from 'modules/components/Feedback/Feedback';
import { OrganizationContext } from 'context/organization/organization';
import { UserContext } from 'context/user/user';
import { AppContext } from 'context/app';
import { Skeleton } from '@material-ui/lab';
import OrganizationPopup from './components/organization-popup';

const SidebarContent = getSidebarContent(styled);
const DrawerSidebar = getDrawerSidebar(styled);

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: '#F5F5F5',
    overflowX: 'hidden',
  },
  sideBar: {
    padding: '1rem',
  },
  sideBarTitle: {
    fontFamily: 'Rubik, sans-serif',
    textAlign: 'center',
    color: '#2F49D0',
  },
  sideBarLine: {
    border: '1px solid #EBEBEB',
    margin: '1rem',
  },
  sidebarLinks: {
    textDecoration: 'none',
    color: '#282828b8',
    fontFamily: 'Rubik, sans-serif',
    fontStyle: 'normal',
    fontSize: '13px',
    lineHeight: '17px',
  },
  sideBarCompanies: {
    fontFamily: 'Rubik, sans-serif',
    flex: 1,
    padding: '0rem .4rem',
    maxWidth: '120px',
  },
  sideBarList: {
    padding: '3px 0 3px 0rem',
  },
  companiesContent1: {
    fontFamily: 'inherit',
    fontSize: '13px',
    fontWeight: '500',
  },
  companiesContent2: {
    fontFamily: 'inherit',
    fontSize: '13px',
    fontWeight: '400',
    color: '#6C6B6B',
    lineHeight: '15.41px',
  },
  nameCard: {
    height: '43px',
    width: '43px',
    color: '#fff',
    borderRadius: '50%',
    marginRight: '.7rem',
    [theme.breakpoints.down('xs')]: {
      margin: 0,
    },
  },
  name: {
    margin: 0,
    textAlign: 'center',
    color: '#00DC7D',
    fontSize: '1.2rem',
    marginTop: '0.55rem',
  },
  nameTitle: {
    margin: 0,
    textAlign: 'center',
    color: '#fff',
    fontSize: '1.2rem',
    marginTop: '0.55rem',
  },
  sidebarItems: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '2rem',
    cursor: 'pointer',
    tapHighlightColor: 'transparent',
    webkitTapHighlightColor: 'transparent',
    mozTapHighlightColor: 'transparent',
    oTapHighlightColor: 'transparent',
  },
  sidebarItemTitle: {
    fontFamily: 'Rubik, sans-serif',
    color: '#878181',
    fontSize: '13px',
    flex: 1,
    lineHeight: '17px',
    '&:hover': {
      color: '#464646',
    },
    marginLeft: '.7rem',
  },
  storage: {
    background: '#1F53D7',
    borderRadius: '3px',
    height: '104px',
    marginTop: '8rem',
    padding: '.7rem',
    color: '#fff',
  },
  progressColor: {
    backgroundColor: '#fff',
  },
  progressPrimary: {
    backgroundColor: '#C4C4C4',
  },
  linearLabel: {
    fontSize: '11px',
    textAlign: 'end',
  },
  active: {
    fontFamily: 'Rubik, sans-serif',
    fontSize: '13px',
    flex: 1,
    lineHeight: '17px',
    color: '#000',
    marginLeft: '.7rem',
  },
  dashboardLink: {
    fontFamily: 'Rubik, sans-serif',
    fontSize: '13px',
    flex: 1,
    lineHeight: '17px',
    marginLeft: '.7rem',
  },
  connection: {
    display: 'flex',
    columnGap: '5px',
    alignItems: 'center',
    overflow: 'hidden',
    padding: '0 6px',
    fontFamily: 'Rubik, sans-serif',
    fontSize: '10px',
    color: '#878181',
    fontStyle: 'italic',
    '& img': {
      width: 30,
      height: 30,
      objectFit: 'contain',
    },
  },
  tooltip: {
    position: 'relative',
    '& .tooltipText': {
      transition: 'all linear .25s',
      color: '#FF9129',
      transform: 'scale(0)',
      width: '150px',
      backgroundColor: '#fff',
      boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
      textAlign: 'center',
      borderRadius: '6px',
      padding: '5px 0',
      position: 'absolute',
      top: -30,
      zIndex: 1,
    },
    '&:hover': {
      '& .tooltipText': {
        transform: 'scale(1)',
      },
    },
    '& .standard': {
      color: '#1F53D7',
    },
  },
}));

export default function SideBar({ path, url, setOpen }) {
  const classes = useStyles();
  const { pathname } = useLocation();
  const history = useHistory();
  const userIndex = new User();
  const { plan, organization } = React.useContext(OrganizationContext);
  const { organizations } = React.useContext(AppContext);
  const { user } = React.useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const planName = plan?.planMeta?.name;

  const selectedOrganization = (data) => {
    localStorage.setItem('organizationId', data?.id);
    localStorage.setItem('ORGANIZATION', JSON.stringify(data));
    location.reload();
  };

  const [values, setValues] = useState({
    accounting: {
      title: '',
      active: false,
    },
    human_resource: {
      title: '',
      active: false,
    },
    invoice: {
      title: '',
      active: false,
    },
    tax: {
      title: '',
      active: false,
    },
    bank: {
      title: '',
      active: false,
    },
  });
  const handleSideBarContents = (name) => () => {
    let data = { ...values };
    for (let v in data) {
      if (name != v) data[v].active = false;
    }
    setValues({
      ...data,
      [name]: { ...data[name], title: name, active: !data[name].active },
    });
  };

  let hrLinks = [
    {
      title: 'Employees',
      path: 'employees',
      permission: true,
    },
    {
      title: 'Leave Management',
      path: 'leave',
      permission: true,
    },
    {
      title: 'Payroll Management',
      path: 'payroll',
      permission: true,
      planName: 'Standard',
    },
    {
      title: 'Payslip Management',
      path: 'payslip',
      permission: true,
      planName: 'Standard',
    },
    {
      title: 'Query Management',
      path: 'query',
      permission: planName !== 'Simple Start',
      planName: 'Standard',
    },
    {
      title: 'Transfer Management',
      path: 'transfer',
      permission: planName !== 'Simple Start',
      planName: 'Standard',
    },
    {
      title: 'Suspension Management',
      path: 'suspension',
      permission: planName !== 'Simple Start',
      planName: 'Standard',
    },
    {
      title: 'Promotion Management',
      path: 'promotions',
      permission: planName !== 'Simple Start',
      planName: 'Standard',
    },
    {
      title: 'Termination/Retirement',
      path: 'termination-and-retirement',
      permission: true,
    },
    {
      title: 'Performance Evaluation',
      path: 'performance-evaluation',
      permission: planName === 'Premium',
      planName: 'Premium',
    },
    {
      title: 'Internal Memo',
      path: 'memo',
      permission: planName !== 'Simple Start',
      planName: 'Standard',
    },
    {
      title: 'Meeting',
      path: 'meeting',
      permission: planName === 'Premium',
      planName: 'Premium',
    },
  ];

  function getHRlinks() {
    let access = hrLinks.filter((item) => item?.permission);
    let noAccess = hrLinks
      .filter((item) => !item?.permission)
      .sort((a, b) => (b.planName > a.planName ? 1 : -1));
    return [...access, ...noAccess];
  }

  let accountLinks = [
    {
      title: 'Customers',
      path: 'customers',
      permission: true,
    },
    {
      title: 'Inflow',
      path: 'inflow',
      permission: true,
    },
    {
      title: 'Outflow',
      path: 'outflow',
      permission: true,
    },
    {
      title: 'Journals',
      path: 'journals',
      permission: planName !== 'Simple Start',
      planName: 'Standard',
    },
    {
      title: 'Quotation',
      path: 'quotation',
      permission: planName !== 'Simple Start',
      planName: 'Standard',
    },
    {
      title: 'Transactions',
      path: 'transactions',
      permission: true,
    },
    {
      title: 'Vendors',
      path: 'vendors',
      permission: true,
    },
    {
      title: 'Product and Services',
      path: 'ps',
      permission: true,
    },
    {
      title: 'Charts of Accounts',
      path: 'charts-of-accounts',
      permission: true,
    },
    {
      title: 'Fixed Assets',
      path: 'fixed-assets',
      permission: planName === 'Premium',
      planName: 'Premium',
    },
    {
      title: 'Budget',
      path: 'budget',
      permission: planName === 'Premium',
      planName: 'Premium',
    },
  ];

  function getACClinks() {
    let access = accountLinks.filter((item) => item?.permission);
    let noAccess = accountLinks
      .filter((item) => !item?.permission)
      .sort((a, b) => (b.planName > a.planName ? 1 : -1));
    return [...access, ...noAccess];
  }

  const bankLinks = [
    {
      title: 'Upload Bank Statement',
      path: 'connected-banks',
    },
  ];

  const reports = [
    {
      title: 'Reports',
      path: 'reports',
    },
  ];

  const taxLinks = [
    {
      title: 'Tax Calendar',
      path: 'tax-calendar',
      permission: true,
    },
  ];

  return (
    <>
      <div>
        <OrganizationPopup
          onClose={() => setAnchorEl(null)}
          open={anchorEl}
          user={user}
        />
      </div>
      <DrawerSidebar sidebarId={'unique_id'} classes={{ paper: classes.paper }}>
        <SidebarContent className={classes.sideBar}>
          <section>
            <div style={{ position: 'relative' }}>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <img
                  src={BrandLogo}
                  alt="brand"
                  style={{ width: '7rem', height: '3rem' }}
                />
              </div>
              <Divider style={{ margin: '20px 0' }} />
              <Paper className="border rounded bg-white p-2 mb-4">
                {!organization ? (
                  <React.Fragment>
                    <div className="d-flex gap-3 align-items-center w-100">
                      <div>
                        <Skeleton variant="circle" width={40} height={40} />
                      </div>
                      <div className="w-100">
                        <Skeleton variant="text" />
                        <Skeleton variant="text" />
                      </div>
                    </div>
                  </React.Fragment>
                ) : (
                  <Box className="d-flex p-1">
                    <div className="d-flex align-items-center gap-1 flex-grow-1">
                      <Avatar
                        src={organization?.logoUploadMeta?.url}
                        alt={organization?.name}
                        style={{
                          backgroundColor: organization?.logoUploadMeta?.url
                            ? ''
                            : getAlphabethColor(organization?.name?.charAt(0)),
                        }}>
                        {organization?.name?.charAt(0)}
                      </Avatar>
                      <div className={classes.sideBarCompanies}>
                        <Typography
                          variant="h4"
                          style={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                          }}
                          className={classes.companiesContent1}>
                          {organization?.name}
                        </Typography>
                        <Typography
                          component="p"
                          style={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                          }}
                          className={classes.companiesContent2}>
                          {organization?.sector || ''}
                        </Typography>
                      </div>
                    </div>
                    <div>
                      <IconButton
                        size="small"
                        onClick={(e) =>
                          !organization ? null : setAnchorEl(e.currentTarget)
                        }>
                        <ExpandMoreIcon />
                      </IconButton>
                    </div>
                  </Box>
                )}
              </Paper>
            </div>
            {organization ? (
              <div>
                <Link
                  to="/dashboard"
                  onClick={() => setOpen('unique_id', false)}
                  replace={pathname == '/dashboard'}
                  style={{
                    textDecoration: 'none',
                    color: pathname.substr(10) == '' ? '#1F53D7' : '#878181',
                    WebkitTapHighlightColor: 'transparent',
                  }}>
                  <div
                    className={classes.sidebarItems}
                    onClick={handleSideBarContents}>
                    <DashboardOutlined
                      style={{
                        color:
                          pathname.substr(10) == '' ? '#1F53D7' : '#878181',
                      }}
                    />
                    <Typography variant="h5" className={classes.dashboardLink}>
                      Dashboard
                    </Typography>
                  </div>
                </Link>
                <Link
                  to={`/dashboard/ic/invoices`}
                  onClick={() => setOpen('unique_id', false)}
                  replace={false}
                  style={{
                    textDecoration: 'none',
                    color:
                      pathname.includes(`${'/ic/invoices'}`) ||
                      pathname.includes(`${'/ic/credit-notes'}`)
                        ? '#1F53D7'
                        : '#878181',
                    WebkitTapHighlightColor: 'transparent',
                  }}>
                  <div
                    className={classes.sidebarItems}
                    onClick={handleSideBarContents}>
                    <Invoice
                      style={{
                        fill: '#878181',
                      }}
                    />
                    <Typography variant="h5" className={classes.dashboardLink}>
                      Invoice
                    </Typography>
                  </div>
                </Link>
                <div
                  className={classes.sidebarItems}
                  style={values.accounting.active ? { marginBottom: 0 } : {}}
                  onClick={handleSideBarContents('accounting')}>
                  <Accounting />
                  <Typography
                    variant="h5"
                    className={
                      values.accounting.active
                        ? classes.active
                        : classes.sidebarItemTitle
                    }>
                    Accounting
                  </Typography>
                  {values.accounting.active ? (
                    <KeyboardArrowDownIcon />
                  ) : (
                    <KeyboardArrowRightIcon />
                  )}
                </div>
                {values.accounting.active && (
                  <ul style={{ listStyle: 'none', paddingLeft: '30px' }}>
                    {getACClinks().map((links, i) => (
                      <Link
                        key={i}
                        to={`${url}/${links.path}`}
                        replace={pathname == `${url}/${links.path}`}
                        className={classes.sidebarLinks}
                        onClick={() => setOpen('unique_id', false)}
                        style={{
                          color:
                            (pathname == '/dashboard/reconciliation/import' &&
                              'transactions' == links.path) ||
                            !links.permission
                              ? '#bbb'
                              : pathname.includes(`${links.path}`)
                              ? '#2F49D0'
                              : '#282828b8',
                        }}>
                        <li
                          style={{
                            marginBottom:
                              i === accountLinks.length - 1 ? 20 : 0,
                          }}
                          className={`${classes.sideBarList} ${classes.tooltip}`}
                          key={i}>
                          {!links.permission && (
                            <span
                              className={`tooltipText ${
                                links.planName === 'Standard' && 'standard'
                              }`}>
                              {links.planName} Feature
                            </span>
                          )}
                          {links.title}
                          {!links.permission && (
                            <img
                              style={{ marginLeft: 8 }}
                              src={
                                links.planName === 'Standard'
                                  ? StandardCrown
                                  : PremiumCrown
                              }
                              alt={links.title}
                            />
                          )}
                        </li>
                      </Link>
                    ))}
                  </ul>
                )}
                <div
                  className={classes.sidebarItems}
                  style={
                    values.human_resource.active ? { marginBottom: 0 } : {}
                  }
                  onClick={handleSideBarContents('human_resource')}>
                  <Humanresource />

                  <Typography
                    variant="h5"
                    className={
                      values.human_resource.active
                        ? classes.active
                        : classes.sidebarItemTitle
                    }>
                    Human Resource
                  </Typography>
                  {values.human_resource.active ? (
                    <KeyboardArrowDownIcon />
                  ) : (
                    <KeyboardArrowRightIcon />
                  )}
                </div>
                {values.human_resource.active && (
                  <ul style={{ listStyle: 'none', paddingLeft: '30px' }}>
                    {getHRlinks().map((links, i) => (
                      <Link
                        key={i}
                        to={links.permission ? `${url}/${links.path}` : '#'}
                        replace={pathname == `${url}/${links.path}`}
                        className={classes.sidebarLinks}
                        onClick={() => setOpen('unique_id', false)}
                        style={{
                          color: !links.permission
                            ? '#bbb'
                            : pathname.includes(`${links.path}`)
                            ? '#2F49D0'
                            : '#282828b8',
                        }}>
                        <li
                          style={{
                            marginBottom: i === hrLinks.length - 1 ? 20 : 0,
                          }}
                          className={`${classes.sideBarList} ${classes.tooltip}`}
                          key={i}>
                          {!links.permission && (
                            <span
                              className={`tooltipText ${
                                links.planName === 'Standard' && 'standard'
                              }`}>
                              {links.planName} Feature
                            </span>
                          )}

                          {links.title}
                          {!links.permission && (
                            <img
                              style={{ marginLeft: 8 }}
                              src={
                                links.planName === 'Standard'
                                  ? StandardCrown
                                  : PremiumCrown
                              }
                              alt={links.title}
                            />
                          )}
                        </li>
                      </Link>
                    ))}
                  </ul>
                )}

                <React.Fragment>
                  <div
                    className={classes.sidebarItems}
                    style={values.tax.active ? { marginBottom: 0 } : {}}
                    onClick={handleSideBarContents('tax')}>
                    <SvgIcon style={{ color: 'red' }}>
                      <Tax />
                    </SvgIcon>
                    <Typography
                      variant="h5"
                      className={
                        values.tax.active
                          ? classes.active
                          : classes.sidebarItemTitle
                      }>
                      Tax
                    </Typography>
                    {values.tax.active ? (
                      <KeyboardArrowDownIcon />
                    ) : (
                      <KeyboardArrowRightIcon />
                    )}
                  </div>
                  {values.tax.active && (
                    <ul style={{ listStyle: 'none', paddingLeft: '30px' }}>
                      {taxLinks.map((links, i) => (
                        <Link
                          key={i}
                          to={links.permission ? `${url}/${links.path}` : '#'}
                          replace={pathname == `${url}/${links.path}`}
                          className={classes.sidebarLinks}
                          onClick={() => setOpen('unique_id', false)}
                          style={{
                            color: pathname.includes(`${links.path}`)
                              ? '#2F49D0'
                              : '#282828b8',
                          }}>
                          <li
                            style={{
                              marginBottom: i === hrLinks.length - 1 ? 20 : 0,
                            }}
                            className={`${classes.sideBarList} ${classes.tooltip}`}
                            key={i}>
                            {!links.permission && (
                              <span
                                className={`tooltipText ${
                                  links.planName === 'Standard' && 'standard'
                                }`}>
                                {links.planName} Feature
                              </span>
                            )}

                            {links.title}
                            {!links.permission && (
                              <img
                                style={{ marginLeft: 8 }}
                                src={
                                  links.planName === 'Standard'
                                    ? StandardCrown
                                    : PremiumCrown
                                }
                                alt={links.title}
                              />
                            )}
                          </li>
                        </Link>
                      ))}
                    </ul>
                  )}
                </React.Fragment>

                <div
                  className={classes.sidebarItems}
                  style={values.bank.active ? { marginBottom: 0 } : {}}
                  onClick={handleSideBarContents('bank')}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none">
                    <path
                      d="M9 5H7C6.46957 5 5.96086 5.21071 5.58579 5.58579C5.21071 5.96086 5 6.46957 5 7V19C5 19.5304 5.21071 20.0391 5.58579 20.4142C5.96086 20.7893 6.46957 21 7 21H17C17.5304 21 18.0391 20.7893 18.4142 20.4142C18.7893 20.0391 19 19.5304 19 19V7C19 6.46957 18.7893 5.96086 18.4142 5.58579C18.0391 5.21071 17.5304 5 17 5H15M9 5C9 5.53043 9.21071 6.03914 9.58579 6.41421C9.96086 6.78929 10.4696 7 11 7H13C13.5304 7 14.0391 6.78929 14.4142 6.41421C14.7893 6.03914 15 5.53043 15 5M9 5C9 4.46957 9.21071 3.96086 9.58579 3.58579C9.96086 3.21071 10.4696 3 11 3H13C13.5304 3 14.0391 3.21071 14.4142 3.58579C14.7893 3.96086 15 4.46957 15 5M9 14L11 16L15 12"
                      stroke="#232325"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <Typography
                    variant="h5"
                    className={
                      values.bank.active
                        ? classes.active
                        : classes.sidebarItemTitle
                    }>
                    Reconciliation
                  </Typography>
                  {values.bank.active ? (
                    <KeyboardArrowDownIcon />
                  ) : (
                    <KeyboardArrowRightIcon />
                  )}
                </div>
                {values.bank.active && (
                  <ul style={{ listStyle: 'none', paddingLeft: '30px' }}>
                    {bankLinks.map((links, i) => (
                      <Link
                        key={i}
                        to={`${url}/${links.path}`}
                        replace={pathname == `${url}/${links.path}`}
                        className={classes.sidebarLinks}
                        onClick={() => setOpen('unique_id', false)}
                        style={{
                          color: pathname.includes(`${links.path}`)
                            ? '#2F49D0'
                            : '#282828b8',
                        }}>
                        <li
                          style={{
                            marginBottom: i === bankLinks.length - 1 ? 20 : 0,
                          }}
                          className={classes.sideBarList}
                          key={i}>
                          {links.title}
                        </li>
                      </Link>
                    ))}
                  </ul>
                )}

                <Link
                  to="/dashboard/reports"
                  style={{ textDecoration: 'none' }}>
                  <div
                    className={classes.sidebarItems}
                    onClick={handleSideBarContents}>
                    <Reports />
                    <Typography
                      variant="h5"
                      className={classes.sidebarItemTitle}>
                      Reports
                    </Typography>
                  </div>
                </Link>
              </div>
            ) : null}
          </section>
        </SidebarContent>
      </DrawerSidebar>
    </>
  );
}
